import { template as template_76fc48143bac45ebb7a9c338792b00e7 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './info.strict-module.css';
export default class ApprovalWorkflowConditionInfo extends Component {
    @service
    intl;
    formattedValue(value, isAmountB) {
        let valueAsNumber = parseFloat(value, 10);
        if (isAmountB) valueAsNumber = valueAsNumber - 0.01;
        return this.intl.formatNumber(valueAsNumber, {
            currency: 'EUR',
            style: 'currency',
            maximumFractionDigits: 2
        });
    }
    get captionMessage() {
        let { conditionAmount, nextRulesetConditionAmount } = this.args;
        if (!conditionAmount) return;
        if (nextRulesetConditionAmount) {
            let amountA = this.formattedValue(conditionAmount, false);
            let amountB = this.formattedValue(nextRulesetConditionAmount, true);
            return this.intl.t('approval-workflows.form.conditions.message', {
                amountA,
                amountB
            });
        }
    }
    static{
        template_76fc48143bac45ebb7a9c338792b00e7(`
    {{#if @isVisible}}
      <p
        class='caption {{styles.info}}'
        data-test-workflow-form-condition-amount-caption-step
        ...attributes
      >{{this.captionMessage}}</p>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
