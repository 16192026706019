import { template as template_5f9284cbc08c423eab7a0d0aefc7efd2 } from "@ember/template-compiler";
import { fn, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Tooltip from '@qonto/ui-kit/components/tooltip';
import themedAsset from '@qonto/ui-kit/helpers/themed-asset';
import { t } from 'ember-intl';
import { variation } from 'ember-launch-darkly';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import Timeline from 'qonto/components/stepper/timeline';
import { OPERATION_TYPES, REQUEST_TYPES } from 'qonto/constants/approval-workflow';
import Condition from './condition';
import OrderedSteps from './ordered-steps';
import styles from './ruleset.strict-module.css';
const ROLES = {
    ADMIN: 'admin',
    MANAGER: 'manager'
};
const MAX_STEPS = 5;
export default class ApprovalWorkflowFormRuleset extends Component {
    // State to track if approvers selector dropdown should remain open
    // The dropdown closes automatically because we generate a new list of option each time the user selects an option
    @tracked
    approversSelectorsTriggerMap = this.stepsByOrder.map((_, orderIndex)=>({
            index: orderIndex,
            shouldOpen: false,
            lastSelection: null
        }));
    @service
    intl;
    @service
    store;
    @service
    organizationManager;
    @action
    updateTriggerMap(indexToRemainOpen, lastSelection) {
        this.approversSelectorsTriggerMap = this.stepsByOrder.map((_, orderIndex)=>({
                index: orderIndex,
                shouldOpen: orderIndex === indexToRemainOpen,
                lastSelection
            }));
    }
    #approverRoles(isPayer) {
        return {
            admin: this.intl.t('approval-workflows.roles.admin'),
            manager: isPayer ? this.intl.t('approval-workflows.roles.manager-payer') : this.intl.t('approval-workflows.roles.manager'),
            owner: this.intl.t('approval-workflows.roles.owner')
        };
    }
    #approverOptions(approverOptions, isPayer) {
        return function approverOptionsFnc(includeManagerAndAdmin = true) {
            let options = approverOptions || [];
            if (includeManagerAndAdmin) {
                let managerDescription = isPayer ? this.intl.t('approval-workflows.possible-approvers.manager-description-can-transfer') : this.intl.t('approval-workflows.possible-approvers.manager-description');
                options = [
                    {
                        description: managerDescription,
                        id: ROLES.MANAGER,
                        name: this.intl.t('approval-workflows.possible-approvers.manager'),
                        role: ROLES.MANAGER
                    },
                    {
                        description: this.intl.t('approval-workflows.possible-approvers.admin-description'),
                        id: ROLES.ADMIN,
                        name: this.intl.t('approval-workflows.possible-approvers.admin'),
                        role: ROLES.ADMIN
                    },
                    ...options
                ];
            }
            return options.map((approver)=>{
                return {
                    description: approver.description || this.#approverRoles(isPayer)[approver.role],
                    key: approver.id,
                    role: approver.role,
                    value: approver.name
                };
            });
        };
    }
    get stepsByOrder() {
        return this.args.ruleset.steps.reduce(this.#groupByOrder, []).filter(Boolean).map(this.#addOptions, this).map(this.#disableApproverOptionsSelectedInOtherSteps);
    }
    get approverStepsByOrder() {
        return this.stepsByOrder.slice(0, -1);
    }
    get payerStepsByOrder() {
        return this.stepsByOrder.at(-1);
    }
    #addOptions(steps, index, arr) {
        let options = this.args.approverOptions;
        let isPayer = false;
        // only the last step can be a payer step
        if (index + 1 === arr.length) {
            options = this.args.payerOptions;
            isPayer = true;
        }
        return this.#addApproverOptions(this.#approverOptions(options, isPayer).bind(this))(...arguments);
    }
    #groupByOrder(acc, item) {
        if (!acc[item.order]) {
            acc[item.order] = [];
        }
        acc[item.order].push(item);
        return acc;
    }
    #addApproverOptions(approverOptionsFnc) {
        return (steps)=>{
            let isOperationAllOf = steps.every((step)=>step.operation === OPERATION_TYPES.ALL_OF);
            steps.approverOptions = approverOptionsFnc(!isOperationAllOf).map((approver)=>{
                return {
                    disabled: this.#isAdminRoleSelectedAndNotRoleOption(approver, steps),
                    selected: this.#isAdminRoleSelectedOrStepSelected(approver, steps),
                    ...approver
                };
            });
            return steps;
        };
    }
    #disableApproverOptionsSelectedInOtherSteps(orderSteps, _, stepsByOrder) {
        orderSteps.approverOptions = orderSteps.approverOptions.map((approverOption)=>{
            return {
                ...approverOption,
                disabled: approverOption.disabled || stepsByOrder.some((otherOrderSteps)=>otherOrderSteps[0].order !== orderSteps[0].order && otherOrderSteps.approverOptions.find(({ key })=>key === approverOption.key)?.selected)
            };
        });
        return orderSteps;
    }
    #isAdminRoleSelectedAndNotRoleOption(approver, steps) {
        return steps.some((step)=>this.#isAdminRoleSelected(approver, step) && approver.key !== approver.role);
    }
    #isAdminRoleSelectedOrStepSelected(approver, steps) {
        return steps.some((step)=>this.#isAdminRoleSelected(approver, step) || step.value === approver.key);
    }
    #isAdminRoleSelected(approver, step) {
        return step.value === approver.role && step.value === ROLES.ADMIN;
    }
    @action
    onApproversChange(updateTriggerMap, index, orderSteps, approverOptions, selectedApproverOptions, state) {
        updateTriggerMap(index, state.highlighted);
        selectedApproverOptions ||= []; // if clear button is pressed
        approverOptions.forEach((approver)=>{
            if (!approver.selected && selectedApproverOptions.includes(approver)) {
                this.#addApprover(orderSteps, approver.key, approverOptions);
            } else if (approver.selected && !selectedApproverOptions.includes(approver)) {
                this.#removeApprover(orderSteps, approver.key, approverOptions);
            }
        });
    }
    #addApprover(orderSteps, approverId, approverOptions) {
        let step = orderSteps[0];
        let newStep = this.store.createRecord('approval-workflow/step', {
            field: Object.values(ROLES).includes(approverId) ? 'role' : 'membership',
            operation: step.operation,
            order: step.order,
            value: approverId
        });
        let { keep, remove } = this.args.ruleset.steps.reduce(({ keep, remove }, step)=>{
            if ((step.value === undefined && step.order === newStep.order) || // remove empty step
            this.#isAdminOptionAndStepIsAdminApprover(step, approverOptions, approverId)) {
                return {
                    keep,
                    remove: [
                        ...remove,
                        step
                    ]
                };
            } else {
                return {
                    keep: [
                        ...keep,
                        step
                    ],
                    remove
                };
            }
        }, {
            keep: [],
            remove: []
        });
        let sortedSteps = [
            ...keep,
            newStep
        ].sort((a, b)=>a.order - b.order);
        this.args.ruleset.steps = sortedSteps;
        remove.forEach((step)=>step.unloadRecord());
    }
    #removeApprover(orderSteps, approverId, approverOptions) {
        let step = orderSteps[0];
        let { keep, remove } = this.args.ruleset.steps.reduce(({ keep, remove }, step)=>{
            if (step.value === approverId || this.#isAdminOptionAndStepIsAdminApprover(step, approverOptions, approverId)) {
                return {
                    keep,
                    remove: [
                        ...remove,
                        step
                    ]
                };
            } else {
                return {
                    keep: [
                        ...keep,
                        step
                    ],
                    remove
                };
            }
        }, {
            keep: [],
            remove: []
        });
        // keep atleast one empty step for each order
        let keepOrderSteps = keep.reduce(this.#groupByOrder, [])[step.order];
        if (keepOrderSteps === undefined || keepOrderSteps.length === 0) {
            keep = [
                ...keep,
                this.store.createRecord('approval-workflow/step', {
                    operation: step.operation,
                    order: step.order
                })
            ];
        }
        this.args.ruleset.steps = keep;
        remove.forEach((step)=>step.unloadRecord());
    }
    #isAdminOptionAndStepIsAdminApprover(step, approverOptions, approverId) {
        if (approverId === ROLES.ADMIN) {
            let approverOption = approverOptions.find((approver)=>step.value === approver.key);
            return approverOption?.role === ROLES.ADMIN;
        }
    }
    handleStepsAfterOperationTypeChange = (ruleset, orderSteps, order)=>{
        let filteredSteps = ruleset.steps.filter((step)=>{
            if (step.order === order) {
                let isRole = step.value === ROLES.ADMIN || step.value === ROLES.MANAGER;
                return !isRole;
            }
            return true;
        });
        // Checks which users were preselected from selecting the role
        // When switching to all_of, we keep the users that were preselected
        let disabledAndSelectedUsers = orderSteps.approverOptions.filter((option)=>option.disabled && option.selected);
        disabledAndSelectedUsers = disabledAndSelectedUsers.map((user)=>{
            return this.store.createRecord('approval-workflow/step', {
                operation: OPERATION_TYPES.ALL_OF,
                order,
                value: user.key,
                field: 'membership'
            });
        });
        // keep at least one empty step for each order
        // this prevents the case where all steps are removed
        let isOrderEmpty = filteredSteps.every((step)=>step.order !== order);
        if (!disabledAndSelectedUsers.length && isOrderEmpty) {
            disabledAndSelectedUsers = [
                this.store.createRecord('approval-workflow/step', {
                    operation: OPERATION_TYPES.ALL_OF,
                    order
                })
            ];
        }
        ruleset.steps = [
            ...filteredSteps,
            ...disabledAndSelectedUsers
        ];
    };
    handleOperationTypeChange = (ruleset, orderSteps, value)=>{
        let { order } = orderSteps[0];
        ruleset.steps?.forEach((step)=>{
            if (step.order === order) step.operation = value.key;
        });
        if (value.key === OPERATION_TYPES.ALL_OF) {
            this.handleStepsAfterOperationTypeChange(ruleset, orderSteps, order);
        }
    };
    get isConditionDisabled() {
        let { requestType } = this.args;
        if (variation('feature--boolean-improve-builder-aw4si') && requestType === REQUEST_TYPES.SUPPLIER_INVOICE) {
            return false;
        }
        return this.args.index === 0;
    }
    get condition() {
        return this.args.ruleset.conditions[0];
    }
    get conditionErrors() {
        return this.condition?.errors.get('value');
    }
    get cannotAddNewStep() {
        return this.stepsByOrder?.length === MAX_STEPS;
    }
    @action
    addNewStep() {
        if (this.cannotAddNewStep) return;
        let { ruleset } = this.args;
        let highestOrder = ruleset.steps.reduce((highestOrder, { order })=>(highestOrder > order ? highestOrder : order), 0);
        // add new step before payer steps
        let approverSteps = ruleset.steps.filter(({ order })=>order !== highestOrder);
        let payerSteps = ruleset.steps.filter(({ order })=>order === highestOrder);
        let newStep = this.store.createRecord('approval-workflow/step', {
            order: highestOrder
        });
        payerSteps.forEach((step)=>(step.order = highestOrder + 1));
        ruleset.steps = [
            ...approverSteps,
            newStep,
            ...payerSteps
        ];
    }
    handleStepRemoval(ruleset, orderSteps) {
        let { order } = orderSteps[0];
        let steps = ruleset.steps?.filter((step)=>step.order !== order);
        // Reorder the steps to keep the step.order starting from 1 consecutively
        let newOrder = 1;
        let lastStepOriginalOrder;
        steps = steps.map((step, index)=>{
            // First step always gets order 1
            // If the current order is the same as the previous, keep the adjusted order
            if (index !== 0 && step.order !== lastStepOriginalOrder) newOrder++;
            lastStepOriginalOrder = step.order;
            step.order = newOrder;
            return step;
        });
        ruleset.steps = steps;
    }
    get isNotFirstRuleset() {
        return this.args.index > 0;
    }
    get lastStepLabel() {
        return variation('feature--boolean-improve-builder-aw4si') ? this.intl.t('approval-workflows.form.steps.label-last') : this.intl.t('approval-workflows.form.steps.label-last-ff');
    }
    static{
        template_5f9284cbc08c423eab7a0d0aefc7efd2(`
    <fieldset class={{styles.step-wrapper}} data-test-approval-workflow-form-ruleset ...attributes>
      <div class={{styles.ruleset-header}}>
        <Condition
          @amountOnly={{@amountOnly}}
          @disabled={{this.isConditionDisabled}}
          @condition={{this.condition}}
          @onUpdate={{fn @onConditionChange this.condition}}
          @validate={{@validate}}
          @index={{@index}}
          @errors={{this.conditionErrors}}
          @nextRulesetConditionAmount={{@nextRulesetConditionAmount}}
          @selectedSupplierIdsInOtherConditions={{@selectedSupplierIdsInOtherConditions}}
          @selectedUploaderIdsInOtherConditions={{@selectedUploaderIdsInOtherConditions}}
          {{on 'focusout' @validate}}
        />
        {{#if @shouldDisplayDeleteButton}}
          <button
            type='button'
            class='btn btn--icon-only btn--tertiary btn--small {{styles.delete-button}}'
            aria-label={{t 'a11y.buttons.delete-aria-label'}}
            data-test-approval-workflow-form-ruleset-remove-button
            {{on 'click' @removeRuleset}}
          >
            {{svgJar (themedAsset 'icon_cross_outlined') aria-hidden='true'}}
          </button>
        {{/if}}
      </div>
      <hr class={{styles.divider}} />
      <div class={{styles.ruleset-steps-container}}>
        <Timeline>
          <:steps as |Step|>
            {{#each this.approverStepsByOrder as |orderSteps index|}}
              <Step data-test-approval-workflow-ruleset-step={{index}}>
                <OrderedSteps
                  class='mb-20'
                  @label={{@approvalLabel}}
                  @steps={{orderSteps}}
                  @onUpdate={{@onUpdate}}
                  @ruleset={{@ruleset}}
                  @index={{index}}
                  @numberOfOrders={{this.stepsByOrder.length}}
                  @onApproversChange={{fn
                    this.onApproversChange
                    this.updateTriggerMap
                    index
                    orderSteps
                  }}
                  @handleOperationTypeChange={{fn
                    this.handleOperationTypeChange
                    @ruleset
                    orderSteps
                  }}
                  @selectedOperation={{get orderSteps '0.operation'}}
                  @handleStepRemoval={{fn this.handleStepRemoval @ruleset orderSteps}}
                  @approversSelectorsTriggerMap={{this.approversSelectorsTriggerMap}}
                  @updateTriggerMap={{this.updateTriggerMap}}
                >
                  {{#if @displayApproversHint}}
                    <p class={{styles.tip}} data-test-approvers-list-tip>
                      <span class='mr-8 caption {{styles.tip-text}}'>
                        {{t 'approval-workflows.form.steps.payers-list.disclaimer'}}
                      </span>
                      <Tooltip
                        @message={{@approversHintMessage}}
                        @position='top center'
                        data-test-approvers-list-tooltip
                      >
                        <span
                          class={{styles.tooltip-icon}}
                          data-test-approvers-list-tooltip-trigger
                        >
                          {{svgJar (themedAsset 'icon_info_rounded_outlined')}}
                        </span>
                      </Tooltip>
                    </p>
                  {{/if}}
                </OrderedSteps>
              </Step>
            {{/each}}
            <Step
              @shouldHideProgressLine={{false}}
              @iconType='plus'
              class={{styles.last-step-container}}
            >
              <button
                type='button'
                class='btn btn--tertiary mb-20 {{styles.add-approval-step}}'
                disabled={{this.cannotAddNewStep}}
                {{on 'click' this.addNewStep}}
                data-test-approval-workflow-ruleset-add-step-button
              >
                {{t 'approval-workflows.form.btn.add-step'}}
              </button>
            </Step>

            {{#let this.payerStepsByOrder this.approverStepsByOrder.length as |orderSteps index|}}
              <Step
                @iconType='payment'
                @shouldHideProgressLine={{true}}
                data-test-approval-workflow-ruleset-step={{index}}
              >
                <OrderedSteps
                  @label={{this.lastStepLabel}}
                  @steps={{orderSteps}}
                  @onUpdate={{@onUpdate}}
                  @ruleset={{@ruleset}}
                  @index={{index}}
                  @numberOfOrders={{this.stepsByOrder.length}}
                  @onApproversChange={{fn
                    this.onApproversChange
                    this.updateTriggerMap
                    index
                    orderSteps
                  }}
                  @handleOperationTypeChange={{fn
                    this.handleOperationTypeChange
                    @ruleset
                    orderSteps
                  }}
                  @selectedOperation={{get orderSteps '0.operation'}}
                  @handleStepRemoval={{fn this.handleStepRemoval @ruleset orderSteps}}
                  @approversSelectorsTriggerMap={{this.approversSelectorsTriggerMap}}
                  @updateTriggerMap={{this.updateTriggerMap}}
                  @isPayerStep={{true}}
                >
                  {{#if @displayPayersHint}}
                    <p class={{styles.tip}} data-test-payers-list-tip>
                      <span class='mr-8 caption {{styles.tip-text}}'>
                        {{t 'approval-workflows.form.steps.payers-list.disclaimer'}}
                      </span>
                      <Tooltip
                        @message={{@payersHintMessage}}
                        @position='top center'
                        data-test-payers-list-tooltip
                      >
                        <span class={{styles.tooltip-icon}} data-test-payers-list-tooltip-trigger>
                          {{svgJar (themedAsset 'icon_info_rounded_outlined')}}
                        </span>
                      </Tooltip>
                    </p>
                  {{/if}}
                </OrderedSteps>
              </Step>
            {{/let}}
          </:steps>
        </Timeline>
      </div>
    </fieldset>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
